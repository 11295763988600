// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: "AIzaSyAFIeOubN31amReVsyaVTP8IodM7YwTXIw",
  authDomain: "mask-map-1e4e4.firebaseapp.com",
  databaseURL: "https://mask-map-1e4e4.firebaseio.com",
  projectId: "mask-map-1e4e4",
  storageBucket: "mask-map-1e4e4.appspot.com",
  messagingSenderId: "247182491022",
  appId: "1:247182491022:web:07eb519dcf303665a338df",
  measurementId: "G-62HXNQT4PN"
};

export default firebaseConfig;
